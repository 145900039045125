<script lang="ts" setup>
const props = defineProps<{
  id: string
  title: string
  isOpen: boolean
}>()
const emit = defineEmits<{
  (e: 'toggle', id: string): void
}>()
const toggleAccordion = () => {
  emit('toggle', props.id)
}


</script>

<template>
  <div class="accordion-wrapper">
  <h3 @click="toggleAccordion">
    {{ title }}
    <button type="button" :id="`accordion-header-${props.id}`" :aria-expanded="props.isOpen" :aria-controls="`accordion-panel-${props.id}`" :aria-label="title">
      <i class="fa fa-chevron-down"></i>
    </button>
  </h3>
  <section
    role="region"
    :id="`accordion-panel-${props.id}`"
    :aria-labelledby="`accordion-header-${props.id}`"
    :inert="!isOpen ? true : undefined"
  >
    <div class="section-inner">
      <div class="inner">
        <slot></slot>
      </div>
    </div>
  </section>
  </div>
</template>

<style lang="postcss" scoped>
.accordion-wrapper {
  border: 1px solid var(--border-color);
  transition: 500ms;
  border-radius: var(--border-radius-medium);
  text-align: start;
  background-color: transparent;
  filter: none;
  transition: 500ms;
  will-change: filter, background-color;
  &:hover,
  &:focus-within {
    background-color: var(--background-color-secondary);
    filter: var(--drop-shadow);
    & button {
      color: var(--text-color-secondary);
    }
  }
  & h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--container-gap);
    color: var(--text-color);
    transition: color 500ms;
    padding: var(--container-gap);
    cursor: pointer;
    margin: 0;
    font-size: 1.8rem;
    border-radius: var(--border-radius-medium);

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.2rem;
      aspect-ratio: 1;
      border: 2px solid currentColor;
      border-radius: var(--border-radius-rounded);
      color: var(--text-color-secondary);
      font-size: 2rem;
      padding: 0;
      transition: 500ms;
      cursor: pointer;
      appearance: none;
      background-color: transparent;
      outline-color: transparent;
      outline-style: solid;
      &[aria-expanded=true] {
        transform: rotateX(180deg);
      }
    }
  }
  & section {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 1fr;
    color: var(--text-color-secondary);
    transition: grid-template-rows 500ms;
    &[inert] {
      grid-template-rows: 0fr;
    }
    & .section-inner {
      overflow: hidden;
      & .inner {
        padding: 0 var(--container-gap) var(--container-gap);
      }
    }
  }
}
</style>
